/**********************
Global Styles
**********************/
*,
*:after,
*:before {
  box-sizing: border-box; }

/** Fixing bootstrap columns for safari **/
.row:after,
.row:before {
  display: inline-block !important; }

body {
  color: #1D1D1D;
  font-size: 17px;
  line-height: 1.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sofia-pro, sans-serif;
  letter-spacing: 0;
  overflow-x: hidden; }

button {
  background-color: transparent;
  border: 0; }
  button:hover {
    cursor: pointer; }
  button:focus {
    outline: 0; }

a,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0; }

section {
  padding: 80px 0; }

.container {
  max-width: 1140px; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.section-title {
  font-size: 46px;
  color: #1D1D1D;
  font-weight: 700; }

.sub-heading {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.02em;
  display: inline-block;
  text-transform: uppercase;
  color: #86807a;
  font-weight: 600; }

strike {
  color: rgba(136, 136, 136, 0.7); }

.img-container {
  text-align: center; }

.section-header {
  margin-bottom: 16px; }

.sale-text {
  font-weight: 700;
  font-size: 50px;
  line-height: 1;
  margin: 20px 0 30px;
  color: #1D1D1D; }
  .sale-text span {
    text-decoration: line-through;
    color: #c9c9c9;
    font-weight: 400; }

.bundle-price {
  font-size: 24px; }

.modal {
  background-color: rgba(0, 0, 0, 0.8); }

/* Buttons */
.btn-prime,
.btn-prime:link,
.btn-prime:visited {
  padding: 10px 25px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  vertical-align: middle;
  box-shadow: none !important;
  outline: 0;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px; }

.btn-prime:hover {
  opacity: 0.9; }

.btn-prime-dark,
.btn-prime-dark:focus,
.btn-prime-dark:hover {
  border: 2px solid #1D1D1D;
  background-color: #1D1D1D;
  color: #fff; }

.btn-prime-border,
.btn-prime-border:focus,
.btn-prime-border:hover {
  background-color: #1D1D1D;
  border: 2px solid #1D1D1D;
  color: #fff; }

.flex-container {
  -webkit-box-align: center;
  align-items: center; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.link-color {
  color: #1D1D1D;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700; }
  .link-color:hover {
    color: #1D1D1D;
    opacity: 0.8; }

@media only screen and (max-width: 767px) {
  section {
    padding: 60px 0; }
  .sub-heading {
    font-size: 17px; } }

@media only screen and (max-width: 600px) {
  .section-title {
    font-size: 32px; } }

.slick-dots li button:before {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-image: url("../img/dot-border.png");
  background-repeat: no-repeat;
  content: "";
  opacity: 1; }

.slick-dots li.slick-active button:before {
  background-image: url("../img/dot-full.png");
  background-repeat: no-repeat; }

.slick-list {
  margin: 0 -20px; }

.slick-slide {
  margin: 0 20px; }

.slick-track {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer; }

/**********************
Navigation
**********************/
header {
  background-color: #1D1D1D;
  box-shadow: none;
  height: 70px; }
  header .container {
    height: 100%; }
  header #main-nav {
    padding: 10px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%; }
    header #main-nav ul.menu-list {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none; }
  header .navbar-dark .navbar-nav .nav-link:hover {
    background-color: transparent;
    color: #1D1D1D; }
  header .buy-now {
    margin-left: 20px;
    background-color: #fff !important;
    color: #1D1D1D !important;
    text-transform: uppercase;
    padding: 10px 35px !important;
    letter-spacing: 2px; }
    header .buy-now:hover, header .buy-now:focus {
      padding: 10px 35px !important;
      letter-spacing: 2px; }
  @media (max-width: 991px) {
    header .navbar-dark .navbar-brand img {
      height: 40px; } }

.nav-link,
.nav-link:active,
.nav-link:focus,
.nav-link:hover,
.nav-link:visited {
  color: #fff;
  font-size: 14px;
  outline: 0;
  font-weight: 600;
  letter-spacing: 0.05em;
  padding: 10px !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase; }
  .nav-link:hover,
  .nav-link:active:hover,
  .nav-link:focus:hover,
  .nav-link:hover:hover,
  .nav-link:visited:hover {
    color: #eee2d7; }

.navbar-dark .navbar-brand {
  font-weight: 700;
  color: #1D1D1D;
  height: 100%;
  padding: 0; }

.navbar-brand {
  max-width: 250px;
  padding: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center; }

.navbar-nav .dropdown-menu {
  left: auto;
  right: 0;
  border: 0;
  border-radius: 0;
  margin-top: 0; }

.cta-btn {
  background-color: #1D1D1D; }

.cta-btn .nav-link {
  color: #fff !important; }

@media only screen and (max-width: 767px) {
  #main-nav {
    display: none !important; } }

/* Mobile Menu */
header {
  background-color: #000;
  position: relative;
  box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2); }

@media screen and (min-width: 992px) {
  .mobile-menu {
    display: none; } }

@media screen and (max-width: 991px) {
  .mobile-nav .btn-prime.btn-prime-dark {
    color: #fff;
    font-size: 26px;
    width: 100%;
    text-transform: uppercase;
    text-transform: inherit; }
  input#burger {
    display: none; }
  .mobile-menu .navbar-brand {
    font-weight: 700;
    color: #1D1D1D; }
  .mobile-menu input:not(:checked) .mobile-links {
    display: none; }
  .mobile-menu input + label {
    position: fixed;
    top: 23px;
    right: 20px;
    height: 20px;
    width: 34px;
    z-index: 5;
    padding: 0;
    margin-bottom: 0; }
  .mobile-menu input + label span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 60%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .mobile-menu input + label span:first-child {
    top: 4px; }
  .mobile-menu input + label span:last-child {
    top: 20px; }
  .mobile-menu label:hover {
    cursor: pointer; }
  .mobile-menu input:checked + label span {
    opacity: 0;
    top: 50%; }
  .mobile-menu input:checked + label span:first-child {
    opacity: 1;
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  .mobile-menu input:checked + label span:last-child {
    opacity: 1;
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  .mobile-menu input ~ nav {
    background: #000;
    box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 3;
    overflow: hidden; }
  .mobile-menu nav {
    position: relative; }
  .mobile-menu input ~ nav > ul {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    margin-bottom: 0;
    width: 80%; }
  .mobile-menu input ~ nav > ul > li {
    display: none; }
  .mobile-menu input ~ nav > ul > li > a {
    color: #fff;
    font-weight: 700;
    display: block;
    padding: 12px;
    font-size: 25px;
    text-transform: uppercase; }
  .mobile-menu input:checked ~ nav {
    height: 100%;
    -webkit-transition-delay: 0s;
    transition-delay: 0s; }
  .mobile-links {
    list-style: none; }
  .mobile-menu input:checked ~ nav > ul > li {
    display: initial; }
  .mobile-menu .navbar-brand {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3000;
    height: 100%;
    padding: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
  .mobile-menu input + label {
    right: 20px; }
  .mobile-menu .navbar-brand {
    left: 15px; } }

@media screen and (max-width: 575px) {
  .mobile-menu input ~ nav > ul > li > a,
  .mobile-nav .btn-prime.btn-prime-dark {
    font-size: 17px; } }

@media (max-width: 450px) {
  .mobile-menu .navbar-brand {
    max-width: 170px; } }

@media screen and (max-width: 375px) {
  .mobile-menu .navbar-brand {
    max-width: 170px; } }

/******************************
Hero
******************************/
.hero {
  background: #DED5CC;
  margin-top: 70px;
  height: 850px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
  align-items: flex-end;
  padding-bottom: 0;
  position: relative; }
  .hero .container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .hero .side-imgs {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0; }
    .hero .side-imgs .left {
      position: absolute;
      bottom: 0;
      left: -40px; }
    .hero .side-imgs .right {
      position: absolute;
      top: 0;
      right: 0;
      width: 10%; }
  .hero .text-area {
    position: relative;
    z-index: 1; }
    .hero .text-area .section-title {
      font-size: 60px;
      line-height: 1; }
      .hero .text-area .section-title span {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 26px;
        display: block; }
    .hero .text-area .sub-heading {
      color: #1D1D1D;
      margin-bottom: 30px; }
    .hero .text-area p {
      max-width: 450px; }
  .hero .img-container {
    text-align: right;
    position: absolute;
    right: 0; }
    .hero .img-container img {
      width: 100%; }

@media only screen and (max-width: 1600px) {
  .hero {
    height: 700px; }
  .hero .img-container img {
    width: 78%; } }

@media only screen and (max-width: 1500px) and (min-width: 841px) {
  .hero .side-imgs .left {
    opacity: 0.4; } }

@media only screen and (max-width: 1500px) {
  .hero .side-imgs .right {
    opacity: 0.4; } }

@media only screen and (max-width: 1199px) {
  .hero .text-area .section-title {
    font-size: 42px; } }

@media only screen and (max-width: 991px) {
  .hero {
    padding-top: 80px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: auto; }
  .hero .container {
    position: relative;
    top: initial;
    left: initial;
    -webkit-transform: none;
    transform: none; }
  .hero .text-area {
    margin-bottom: 40px;
    text-align: center; }
  .hero .img-container {
    text-align: right;
    position: relative;
    right: initial; }
  .hero .text-area .section-title {
    font-size: 40px; }
  .hero .side-imgs .right, .hero .side-imgs .left {
    display: none; } }

@media only screen and (max-width: 840px) {
  .hero {
    padding-top: 60px; }
  .hero .text-area p {
    max-width: 700px;
    margin: 0 auto 20px; }
  .hero .text-area .img-container {
    margin: 0 auto 20px; }
  .hero .img-area {
    margin-top: 0; }
  .hero .img-area {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 50%;
    margin: 30px auto 0; } }

@media only screen and (max-width: 600px) {
  .hero .side-imgs .left {
    display: none; }
  .hero .text-container {
    padding: 0; }
  .hero .text-area .img-container {
    text-align: center;
    max-width: 100px; }
  .hero .text-area .section-title {
    font-size: 27px; }
  .hero .text-area .section-title span {
    font-size: 20px;
    margin-top: 16px;
    margin-bottom: 20px; } }

@media only screen and (max-width: 375px) {
  .hero .text-area .section-title {
    font-size: 27px; } }

/**********************
Logo Slider
**********************/
#logos {
  background-color: #f4f4f4;
  padding: 20px 0; }

@media only screen and (max-width: 420px) {
  #logos .img-container {
    max-width: 100px;
    margin: 0 auto; } }

/******************************
Text Block
******************************/
.text-block {
  text-align: center; }
  .text-block .cta-container {
    text-align: center; }

/**********************
Video
**********************/
.video {
  position: relative;
  text-align: center;
  overflow-x: hidden;
  padding-bottom: 190px; }
  .video p {
    font-weight: 500; }
  .video .section-header {
    margin-bottom: 40px; }
    .video .section-header .section-title span {
      display: block; }
    .video .section-header .sub-heading {
      margin-bottom: 20px; }
  .video .cta-container {
    margin-top: 40px; }
  .video .main-video {
    position: relative; }
    .video .main-video::before {
      position: absolute;
      top: -50px;
      left: -260px;
      content: url("../img/video-shapes-left.png");
      z-index: -1; }
    .video .main-video::after {
      position: absolute;
      bottom: -95px;
      right: -190px;
      content: url("../img/video-shapes-right.png");
      z-index: -1; }
    .video .main-video .video-container {
      position: relative;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center; }
    .video .main-video .img-container {
      position: relative; }
      .video .main-video .img-container::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #1D1D1D;
        opacity: 0.2; }
    .video .main-video .play-btn {
      position: absolute;
      max-width: 100px;
      z-index: 1; }
  .video.part-2 .section-header {
    margin-bottom: 16px; }
  .video.part-2 .container {
    background: transparent !important; }
  .video.part-2 ul {
    margin-bottom: 1rem;
    padding-left: 10px;
    list-style: none; }
    .video.part-2 ul li {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: start;
      align-items: flex-start;
      margin-bottom: 8px; }
      .video.part-2 ul li i {
        margin: 3px 7px 0 0;
        color: #1D1D1D; }
  .video.part-2 .cta-container {
    margin-top: 30px; }

@media only screen and (max-width: 600px) {
  .video {
    padding-bottom: 150px; }
  .video .main-video::before {
    display: none; }
  .video .main-video::after {
    right: -310px; }
  .video .section-header {
    margin-bottom: 20px; } }

/**********************
How It Works
*************************/
.how {
  position: relative;
  text-align: center;
  padding-bottom: 160px; }
  .how::before {
    position: absolute;
    bottom: 0;
    left: -80px;
    content: url("../img/hiw-img-left.png");
    z-index: -1; }
  .how::after {
    position: absolute;
    top: -15px;
    right: -45px;
    content: url("../img/hiw-img-right.png");
    z-index: -1; }
  .how .section-header {
    margin-bottom: 80px; }
    .how .section-header .sub-heading {
      color: #1D1D1D;
      margin-bottom: 30px; }
  .how .icons {
    margin: 0 0 30px; }
    .how .icons .icon {
      max-width: 40%;
      margin: 0 auto 16px; }
      .how .icons .icon p {
        margin-bottom: 0; }
    .how .icons .icon-title {
      font-weight: 700;
      font-size: 24px; }
    .how .icons .icon-text {
      text-transform: uppercase; }
      .how .icons .icon-text span {
        display: block; }
  .how .img-container {
    margin-bottom: 40px; }

@media only screen and (max-width: 1199px) {
  .how::before {
    opacity: 0.4; } }

@media only screen and (max-width: 991px) {
  .how::after {
    opacity: 0.4; }
  .how .section-header {
    margin-bottom: 50px; }
  .how .icon-container:not(:last-child) {
    margin-bottom: 40px; }
  .how .icons .icon-text span {
    display: initial; } }

@media only screen and (max-width: 767px) {
  .how .icon-container:not(:last-child) {
    margin-bottom: 30px; }
  .how::before {
    display: none; } }

@media only screen and (max-width: 600px) {
  .how {
    padding-bottom: 120px; }
  .how::after {
    display: none; } }

@media only screen and (max-width: 375px) {
  .how .icons .icon-title {
    font-size: 22px; } }

/**********************
What's Included
**********************/
.whats-included .container {
  position: relative;
  height: 700px; }
  .whats-included .container::after {
    position: absolute;
    bottom: -108px;
    left: 52px;
    content: url("../img/whats-included-left.png");
    z-index: 0; }
  .whats-included .container::before {
    position: absolute;
    top: 10px;
    right: -155px;
    content: url("../img/whats-included-right.png"); }

.whats-included .section-header {
  text-align: center;
  margin-bottom: 50px; }

.whats-included .text-area {
  background: #C6DEE2;
  padding: 50px;
  position: absolute;
  bottom: 0;
  right: 15px;
  width: 70%; }
  .whats-included .text-area .inner {
    position: relative; }
  .whats-included .text-area .section-title {
    font-size: 32px;
    margin-bottom: 20px; }

.whats-included .img-container {
  position: absolute;
  top: 0;
  left: 15px;
  z-index: 1;
  max-width: 700px; }

.whats-included .included-item span {
  text-decoration: underline; }

.whats-included .included-item:not(:last-child) {
  margin-bottom: 20px; }

.whats-included .cta-container {
  padding: 0 15px;
  margin-top: 200px;
  text-align: center; }

@media only screen and (max-width: 1220px) and (min-width: 992px) {
  .whats-included .img-container .inner::before {
    right: -246px; } }

@media only screen and (max-width: 1199px) {
  .whats-included .img-container {
    max-width: 530px; } }

@media only screen and (max-width: 1060px) {
  .whats-included .img-container {
    bottom: 0; } }

@media only screen and (max-width: 991px) {
  .whats-included {
    text-align: center; }
  .whats-included .container {
    height: auto; }
  .whats-included .container::before {
    display: none; }
  .whats-included .img-container .inner::before {
    top: unset;
    right: -188px;
    bottom: -71px; }
  .whats-included .text-area {
    position: relative;
    padding: 50px 15px 100px;
    bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    margin: 0 auto; }
    .whats-included .text-area .section-title {
      margin-bottom: 25px; }
  .whats-included .section-header {
    margin-bottom: 30px; }
  .whats-included .included-item {
    max-width: 600px;
    margin: 0 auto; }
  .whats-included .img-container {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
    margin: -60px auto 30px; }
  .whats-included .cta-container {
    margin-top: 20px; } }

@media only screen and (max-width: 600px) {
  .whats-included {
    padding-bottom: 0; }
  .whats-included .container::after,
  .whats-included .img-container .inner::before {
    display: none; }
  .whats-included .text-area {
    padding: 50px 15px; }
  .whats-included .text-area .section-title {
    font-size: 23px; }
  .whats-included .img-container {
    margin: 30px auto 0; } }

@media only screen and (max-width: 400px) {
  .whats-included .included-item {
    -webkit-box-pack: left;
    justify-content: left;
    text-align: left; } }

/**********************
Reviews
**********************/
.reviews {
  background: #FED8A3;
  position: relative;
  z-index: 1;
  padding-top: 0;
  margin-top: 80px; }
  .reviews .flex-container {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap; }
  .reviews .section-header {
    margin-bottom: 60px;
    text-align: center; }
  .reviews .img-container img {
    box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2); }
  .reviews .slick-track {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
  .reviews .slick-dotted.slick-slider {
    margin-bottom: 0; }
  .reviews .slick-dots {
    bottom: 0;
    position: relative;
    margin-top: 50px; }
  .reviews .slick-slide img {
    display: initial; }
  .reviews .text-area {
    margin-top: 250px; }
    .reviews .text-area .reviewer {
      text-transform: uppercase; }
      .reviews .text-area .reviewer span {
        display: block; }
  .reviews .events-slider {
    margin-top: -110px; }
  .reviews .product-next,
  .reviews .product-prev {
    cursor: pointer; }
  .reviews .quote {
    font-size: 33px;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 50px; }
  .reviews .reviewer {
    font-size: 18px;
    text-align: right;
    font-weight: 700; }
  .reviews.review-2 {
    margin-top: 0;
    padding-bottom: 200px; }

@media only screen and (max-width: 991px) {
  .reviews .item .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse; }
  .reviews .item.left .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  .reviews .item img {
    margin-bottom: 40px; }
  .reviews .text-area {
    margin-top: 0;
    max-width: 600px;
    margin: 0 auto;
    text-align: center; }
  .reviews .reviewer {
    text-align: center; }
  .reviews .slick-dots {
    margin-top: 30px; } }

@media only screen and (max-width: 600px) {
  .reviews .item img {
    margin-bottom: 30px; }
  .reviews .quote {
    font-size: 20px;
    margin-bottom: 30px; }
  .reviews .reviewer {
    font-size: 17px; }
  .reviews .slick-dots {
    margin-top: 20px; } }

/**********************
Guided meditations 
*************************/
.guided-meditations {
  text-align: center;
  margin-top: 80px;
  position: relative; }
  .guided-meditations .container-fluid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .guided-meditations .container-fluid .left {
      position: absolute;
      top: 30px;
      left: 15px; }
    .guided-meditations .container-fluid .right {
      position: absolute;
      bottom: 0;
      right: 100px; }
  .guided-meditations .container {
    position: relative;
    z-index: 1; }
  .guided-meditations .section-header {
    margin-bottom: 50px; }
  .guided-meditations .grid-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px; }
    .guided-meditations .grid-row .item {
      background-color: #fff;
      padding: 0;
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      -webkit-box-pack: start;
      justify-content: flex-start;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03);
      min-height: 250px; }
      .guided-meditations .grid-row .item:last-child {
        border: none;
        -webkit-box-pack: center;
        justify-content: center; }
        .guided-meditations .grid-row .item:last-child .title {
          text-transform: initial !important; }
      .guided-meditations .grid-row .item .img-container {
        margin: 20px 0 0; }
      .guided-meditations .grid-row .item .price {
        font-weight: 700;
        color: #1D1D1D; }
        .guided-meditations .grid-row .item .price span {
          text-decoration: line-through;
          opacity: 0.3; }
      .guided-meditations .grid-row .item .des {
        padding: 15px; }
        .guided-meditations .grid-row .item .des .title {
          font-weight: 700;
          color: #1D1D1D;
          text-transform: uppercase;
          margin-bottom: 8px; }
        .guided-meditations .grid-row .item .des .bundle-price {
          margin: 20px 0; }
      .guided-meditations .grid-row .item .cta-container {
        margin-top: auto;
        width: 100%; }
        .guided-meditations .grid-row .item .cta-container .btn-prime {
          display: block; }

@media only screen and (max-width: 1400px) {
  .guided-meditations .container::before {
    left: -150px;
    z-index: -1; }
  .guided-meditations .container::after {
    right: -150px;
    z-index: -1; } }

@media only screen and (max-width: 991px) {
  .guided-meditations .grid-row {
    grid-template-columns: repeat(2, 1fr); } }

@media only screen and (max-width: 600px) {
  .guided-meditations .grid-row {
    grid-template-columns: 1fr; } }

.images {
  padding: 0;
  overflow-x: hidden; }
  .images .item {
    border: 8px solid white; }
  .images .slick-slide img {
    width: 100%; }

/**********************
Events
**********************/
.events {
  position: relative;
  text-align: center;
  z-index: 1;
  padding: 0 0 120px; }
  .events::before {
    position: absolute;
    bottom: 0;
    left: -80px;
    content: url("../img/event-img-left.png");
    z-index: -1; }
  .events::after {
    position: absolute;
    top: -15px;
    right: -45px;
    content: url("../img/event-img-right.png");
    z-index: -1; }
  .events .section-header {
    margin-bottom: 50px; }
  .events .grid-row {
    margin-top: -100px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px; }
    .events .grid-row .item {
      background-color: #fff;
      padding: 0;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column; }
      .events .grid-row .item .img-container {
        margin: 0; }
      .events .grid-row .item .price {
        font-weight: 700;
        color: #1D1D1D; }
        .events .grid-row .item .price span {
          text-decoration: line-through;
          opacity: 0.3; }
      .events .grid-row .item .des {
        padding: 15px 35px; }
        .events .grid-row .item .des .title {
          font-weight: 700;
          color: #1D1D1D; }
      .events .grid-row .item .cta-container {
        margin-top: auto;
        width: 100%; }
        .events .grid-row .item .cta-container .btn-prime {
          display: block; }

@media only screen and (max-width: 991px) {
  .events::after {
    display: none; }
  .events .grid-row {
    grid-template-columns: 1fr;
    row-gap: 40px;
    max-width: 600px;
    margin: -100px auto 0; } }

/**********************
About
**********************/
.about {
  background-color: #c6dee2;
  text-align: center; }
  .about .section-header {
    text-align: center;
    margin-bottom: 40px; }
  .about .img-container {
    margin-bottom: 30px; }
  .about .grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px; }
    .about .grid-row .bio {
      padding: 0 40px; }
      .about .grid-row .bio .title {
        font-weight: 700; }

@media only screen and (max-width: 991px) {
  .about {
    padding: 80px 0;
    margin-bottom: 0;
    text-align: center;
    overflow-x: hidden; }
    .about .row-style {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse; } }

@media only screen and (max-width: 767px) {
  .about {
    padding: 60px 0; }
  .about .grid-row .bio {
    padding: 0 15px; } }

@media only screen and (max-width: 600px) {
  .about .grid-row {
    grid-template-columns: 1fr;
    gap: 20px; }
  .about .img-container::after {
    opacity: 0.2; } }

/**********************
Purchase
**********************/
.purchase {
  text-align: center;
  margin-top: 80px; }
  .purchase .section-header {
    margin-bottom: 50px; }
  .purchase .grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px;
    row-gap: 60px; }
    .purchase .grid-row .item {
      background-color: #fff;
      padding: 0;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.03); }
      .purchase .grid-row .item .img-container {
        margin: 0 0 30px; }
      .purchase .grid-row .item .price {
        font-weight: 700;
        color: #1D1D1D; }
        .purchase .grid-row .item .price span {
          text-decoration: line-through;
          opacity: 0.3; }
      .purchase .grid-row .item .des {
        padding: 15px; }
        .purchase .grid-row .item .des .title {
          font-weight: 700;
          color: #1D1D1D; }
      .purchase .grid-row .item .cta-container {
        margin-top: auto;
        width: 100%;
        padding-bottom: 30px; }

@media only screen and (max-width: 991px) {
  .purchase {
    margin-top: 0; } }

@media only screen and (max-width: 600px) {
  .purchase .grid-row {
    grid-template-columns: 1fr; } }

/**********************
FAQ
*************************/
.faq {
  text-align: center;
  font-size: 16px; }
  .faq .section-header {
    margin-bottom: 40px; }
  .faq .arrow {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .faq .link-color {
    text-decoration: underline; }

.rotate {
  -webkit-transform: rotate(0) !important;
  transform: rotate(0) !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

@-webkit-keyframes spin1 {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(180deg); } }

@-keyframes spin1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); } }

.card-header:first-child {
  border-radius: 0; }

.card:last-child .card-header {
  border-bottom: 1px solid #e8e8e8; }

.active-question {
  border-top: 0 !important;
  border-bottom: 0 !important; }

#questions {
  text-align: left; }
  #questions .card {
    border-left: 0;
    border-right: 0; }
    #questions .card:first-child {
      border-top: 0; }
    #questions .card .card-header {
      padding: 0;
      position: relative;
      background-color: #fff;
      border: 0; }
    #questions .card .card-body {
      border-bottom: 0;
      padding: 0 0 20px; }
  #questions .btn-link {
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: left;
    color: #1D1D1D;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 12px 12px 12px 0;
    white-space: inherit;
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8; }
    #questions .btn-link svg {
      position: absolute;
      top: 50%;
      right: 16px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); }

.collapsed.show {
  border-top: 0 !important;
  border-bottom: 0 !important; }

@media only screen and (max-width: 600px) {
  #questions .btn-link {
    font-size: 16px; } }

.subscribe {
  background-color: #eee2d7; }
  .subscribe .section-header {
    text-align: center;
    margin-bottom: 30px; }
    .subscribe .section-header .sub-heading span {
      display: block; }
  .subscribe form {
    max-width: 650px;
    margin: 0 auto; }

#_form_1_ {
  text-align: center; }

#_form_1_submit,
._form-content.form-area input {
  height: 45px; }

._form-content.form-area {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  background: #fff;
  padding: 10px; }

._form-content.form-area input {
  background: #fff;
  width: 100%;
  padding-left: 10px;
  border: none; }

#_form_1_submit {
  background: #242424;
  color: #fff;
  font-size: 12px;
  letter-spacing: 2px;
  padding: 12px 20px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 600; }

.input-area {
  max-width: 760px;
  width: 100%; }

._error-inner {
  text-align: left;
  margin-top: 16px;
  padding: 8px 10px;
  color: red;
  background-color: #fff6f6;
  max-width: 300px; }

._form-thank-you {
  padding: 10px 20px;
  background: #fff; }

@media (max-width: 600px) {
  .subscribe .section-header .sub-heading span {
    display: initial; }
  ._form-content.form-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  ._error-inner {
    max-width: 100%; }
  .input-area {
    width: 100%;
    margin-bottom: 10px; }
  ._form-content.form-area input {
    border-color: #d8d7d7; }
  #_form_1_submit {
    width: 100%; } }

/**********************
Footer
**********************/
footer {
  background-color: #1D1D1D;
  color: #fff;
  padding: 60px 0;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600; }
  footer .mail {
    font-weight: 700;
    margin-left: 10px;
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: lowercase;
    font-size: 16px; }
  footer .copyright {
    margin-top: 7px; }
  footer .btn-prime-border,
  footer .btn-prime-border:focus,
  footer .btn-prime-border:hover {
    background-color: #f9af9f;
    color: #1D1D1D; }
  footer .btn-prime {
    background-color: #f9af9f;
    color: #1D1D1D; }
    footer .btn-prime:hover {
      background-color: #f9af9f;
      color: #1D1D1D; }
    footer .btn-prime strike {
      color: rgba(199, 115, 97, 0.7); }
  footer .footer-content {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between; }
    footer .footer-content.footer-top {
      margin-bottom: 30px; }
      footer .footer-content.footer-top .img-container {
        max-width: 250px; }
  footer .social {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
    footer .social a {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center; }
    footer .social svg {
      margin: 0 7px;
      font-size: 18px;
      color: #fff; }

@media only screen and (max-width: 720px) {
  footer {
    text-align: center; }
  footer .footer-content {
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  footer .img-container {
    margin-bottom: 30px; }
  .social {
    margin-bottom: 15px; } }

/** MEDIA QUERIES **/
/******************************
Mobile - Landscape
******************************/
